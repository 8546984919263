import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setCustomerEmail } from '../../store/slices/formSlice';
import { useTranslation } from '../../utils/translate';
import { TextInput } from '../common/forms';

const Email = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string | null>(
    useSelector((state: RootState) => state.form.email || null)
  );

  useEffect(() => {
    dispatch(setCustomerEmail(email));
  }, [email]);

  return (
    <div className='email formItem'>
      <div className='body'>
        <TextInput
          name={'email'}
          placeholder={t('email')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          value={email}
          label={t('email')}
        />
      </div>
    </div>
  );
};

export default Email;
