import { useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import loadingSpinner from '../../assets/svg/preloader.gif';
import { useTranslation } from '../../utils/translate';

const Loading = () => {
  const { t } = useTranslation();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  return (
    <>
      {isLoading && (
        <div className='loadingScreen'>
          <img src={loadingSpinner} alt='' />
          <div>{t('please_wait')}</div>
        </div>
      )}
    </>
  );
};

export default Loading;
