const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1;

  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

const setCookie = (name: string, value: string): void => {
  const daysToLive = 400; // !!! TODO: TAKE FROM CONF
  let cookie = `${name}=${encodeURIComponent(value)}`;

  if (typeof daysToLive === 'number') {
    cookie += `; max-age=${daysToLive * 24 * 60 * 60}`;
    cookie += '; SameSite=Lax'; // or 'Strict' if preferred
    cookie += '; Secure'; // Ensure the cookie is only sent over HTTPS
    document.cookie = cookie;
  }
};


export { getCookie, setCookie };
