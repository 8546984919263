import { useTranslation } from '../../utils/translate';
import { InfoBox } from '../common';
import { Button } from '../common/forms';
import { addBooking } from '../../services/addBooking';
import RootState from '../../interfaces/root.interface';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setLoading } from '../../store/slices/loadingSlice';
import { setNotification } from '../../store/slices/notificationsSlice';
import { setOverlay } from '../../store/slices/overlaySlice';

const Submit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentFormValues = useSelector((state: RootState) => state.form);
  const isSubmitOverlayActive = useSelector(
    (state: RootState) => state.overlay.onOff
  );
  const [hideButton, setHideButton] = useState<boolean>(false);

  const [showTooManyAttempts, setShowTooManyAttempts] =
    useState<boolean>(false);

  const handleSubmit = async () => {
    dispatch(setLoading(true));

    if (!currentFormValues.email && !currentFormValues.phoneNumber) {
      dispatch(
        setNotification({
          type: 'danger',
          string: t('missingFields')
        })
      );
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
      return;
    }

    const result = await addBooking(currentFormValues);

    if (!result) {
      setHideButton(true);
      dispatch(
        setNotification({
          type: 'danger',
          string: t('errorPosting')
        })
      );
    }

    if (typeof result === 'number' && result === 429) {
      // Show too many attempts
      setShowTooManyAttempts(true);
      setHideButton(true);
      dispatch(
        setNotification({
          type: 'danger',
          string: t('tooManyAttepmtsExplanation')
        })
      );
    }

    if (result && result.status === 201) {
      dispatch(setOverlay(true));
      dispatch(
        setNotification({
          type: 'success',
          string: t('submitted')
        })
      );
    }

    setTimeout(() => {
      dispatch(setLoading(false));
    }, 3000);
    setTimeout(() => {
      dispatch(setOverlay(false));
    }, 5000);
  };

  return (
    <>
      <div className={`submit ${isSubmitOverlayActive && 'show'}`}>
        {!hideButton && (
          <InfoBox
            type={!showTooManyAttempts ? 'info' : 'danger'}
            text={t(
              !showTooManyAttempts
                ? 'submitExplanation'
                : 'tooManyAttepmtsExplanation'
            )}
          />
        )}
        {hideButton && <InfoBox type={'danger'} text={t('errorPosting')} />}
        {!hideButton && (
          <Button
            hidden={showTooManyAttempts}
            type={'success'}
            label={t('submit')}
            onClick={() => handleSubmit()}
          />
        )}
      </div>
    </>
  );
};

export default Submit;
