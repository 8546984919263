interface props {
  id?: string;
  label?: string;
  onClick: () => void;
  type: 'info' | 'danger' | 'warning' | 'success';
  icon?:
    | 'add'
    | 'delete'
    | 'edit'
    | 'save'
    | 'logout'
    | 'login'
    | 'link'
    | 'close'
    | 'arrowLeft'
    | 'arrowRight';
  iconPosition?: 'left';
  customClass?: string;
  disabled?: boolean;
  hidden?: boolean;
}

const Button = (props: props) => {
  const renderIcon = (): JSX.Element | null => {
    switch (props.icon) {
      case 'add':
      case 'delete':
      case 'logout':
      case 'login':
      case 'edit':
      case 'save':
      case 'link':
      case 'close':
      case 'arrowLeft':
      case 'arrowRight':
      default:
        return null;
    }
  };

  return (
    <>
      <button
        id={props.id || ''}
        className={
          'btn btn-' +
          props.type +
          (props.icon ? ' icon ' : ' ') +
          props.customClass +
          (props.iconPosition == 'left' ? ' iconLeft' : '') +
          (props.disabled ? ' disabled' : '')
        }
        onClick={() => {
          !props.disabled ? props.onClick() : null;
        }}
        type='button'
      >
        {props.iconPosition == 'left' ? renderIcon() : null}
        {props.label && <span>{props.label}</span>}
        {props.iconPosition != 'left' ? renderIcon() : null}
      </button>
    </>
  );
};

export default Button;
