import { ServiceForm } from '../interfaces/service.interface';
import { getCookie } from './storageHandler';
import { setAllFormValues } from '../store/slices/formSlice';
import { useDispatch } from 'react-redux';

export const resolveInitialState = (): ServiceForm => {
  const dispatch = useDispatch();
  const serviceForm: ServiceForm = {} as ServiceForm;
  serviceForm.animalType = getCookie('animalType') as 'cat' | 'dog';
  serviceForm.breed = getCookie('breed');
  serviceForm.isMixed = getCookie('isMixed') === 'true' || false;
  serviceForm.phoneNumber = getCookie('phoneNumber');
  serviceForm.animalAge = getCookie('animalAge');
  serviceForm.customerSurname = getCookie('customerSurname');
  serviceForm.email = getCookie('email');

  setTimeout(() => {
    dispatch(setAllFormValues(serviceForm));
  }, 1000);

  return serviceForm;
};
