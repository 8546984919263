import axios, { AxiosResponse } from 'axios';

export const addBooking = async (
  input: any
): Promise<AxiosResponse<number | null>> => {
  const response = await axios.post('api/booking/add', {
    animalType: input.animalType,
    breed: input.breed,
    isMixed: input.isMixed,
    animalAge: input.animalAge,
    phoneNumber: input.phoneNumber,
    customerSurname: input.customerSurname,
    email: input.email,
    dateTime: input.datetime
  });

  return response;
};

export default addBooking;
