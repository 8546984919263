import { useSelector } from 'react-redux';
import RootState from '../interfaces/root.interface';
import ee from '../locales/ee.json';
import en from '../locales/en.json';
import ru from '../locales/ru.json';
import fi from '../locales/fi.json';

const getTranslation = (key: string, obj: Record<string, string>): string => {
  if (obj.hasOwnProperty(key)) {
    return obj[key];
  } else {
    console.warn(`Translation key '${key}' not found.`);
    return key; // Fallback to returning the key itself if translation not found
  }
};

export const useTranslation = () => {
  const language = useSelector((state: RootState) => state.language.language);

  const t = (input: string): string => {
    switch (language) {
      case 'ee':
        return getTranslation(input, ee.translations);
      case 'en':
        return getTranslation(input, en.translations);
      case 'ru':
        return getTranslation(input, ru.translations);
      case 'fi':
        return getTranslation(input, fi.translations);
      default:
        return input;
    }
  };

  return { t };
};