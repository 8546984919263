import { ReactComponent as InfoIcon } from '../../assets/svg/infoIcon.svg';

interface Props {
  type: string;
  text: string;
}

const InfoBox = (props: Props) => {
  return (
    <>
      <div className={'infobox ' + props.type}>
        <div className='inner'>
          <span className='icon'>{props.type === 'info' && <InfoIcon />}</span>
          <span>{props.text}</span>
        </div>
      </div>
    </>
  );
};

export default InfoBox;
