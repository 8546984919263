import './config/axios.ts';
import { Header, Loading, Notifications } from './components/common';
import SubmitOverlay from './components/registration/submitOverlay.tsx';
import { setLoading } from './store/slices/loadingSlice.ts';
import { useDispatch } from 'react-redux';
import { setOverlay } from './store/slices/overlaySlice.ts';
import { resolveInitialState } from './utils/initServices.ts';
import MobileServices from './components/registration/mobileServices.tsx';
import Register from './pages/register.tsx';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'

const App = () => {
  const [isMobileServicesVisible, setIsMobileServicesVisible] = useState(false);

  resolveInitialState();
  const dispatch = useDispatch();
  dispatch(setLoading(false));
  dispatch(setOverlay(false));

  const handleServicesViewChange = (onOff: boolean) => {
    setIsMobileServicesVisible(onOff);
  };

  return (
    <div className='App'>
      <Loading />
      <Notifications />
      <div className='bg'></div>
      <MobileServices onToggleMobileServices={handleServicesViewChange} />
      {!isMobileServicesVisible && (
        <>
          <div className='content'>
            <Register />
          </div>
        </>
      )}
      <SubmitOverlay />
    </div>
  );
};

export default App;
