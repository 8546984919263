import { ChangeEvent } from 'react';

interface props {
  label?: string | boolean;
  name: string;
  value?: string | null;
  placeholder?: string;
  customClass?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  icon?: string;
  disabled?: boolean;
}

const TextInput = (props: props) => {
  const renderIcon = (): JSX.Element | null => {
    // Because we might have other icons inside text input elements, I will use a switch here
    switch (props.icon) {
      case 'search':
        return <div className='searchIcon'></div>;
      default:
        return null;
    }
  };

  return (
    <>
      {props.label && (
        <label className={'label' + (props.required ? ' required' : '')}>
          {props.label}
        </label>
      )}
      <div className='position-relative'>
        <input
          id={props.name}
          className={'form-control ' + (props.customClass || '')}
          name={props.name}
          type='text'
          placeholder={props.placeholder}
          onChange={(e) => props.onChange(e)}
          value={props.value || ''}
          disabled={props.disabled}
        />
        {renderIcon()}
      </div>
    </>
  );
};

export default TextInput;
