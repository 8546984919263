import AnimalAge from './animalAge';
import Breed from './breed';
import AnimalType from './animalType';
import CustomerPhoneNumber from './customerPhoneNumber';
import CustomerSurname from './customerSurname';
import Email from './email';
import Submit from './submit';
import { useTranslation } from '../../utils/translate';
import DateTimePicker from '../common/forms/dateTimepicker';

interface props {
  label?: string | boolean;
  name: string;
  value?: string;
  customClass?: string;
  onChange: (e: string) => void;
  required?: boolean;
  icon?: string;
  noDirectInput?: boolean;
}

const RegistrationBody = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='registrationForm'>
        <div className='registrationFormTitle'>{t('registrationTitle')}</div>
        <ol className='registrationBodyList'>
          <li>
            <div className='separator'></div>
            <AnimalType />
          </li>
          <li>
            <div className='separator'></div>
            <AnimalAge />
          </li>
          <li>
            <div className='separator'></div>
            <Breed />
          </li>
          <li>
            <div className='separator'></div>
            <CustomerPhoneNumber />
          </li>
          <li>
            <div className='separator'></div>
            <Email />
          </li>
          <li>
            <div className='separator'></div>
            <CustomerSurname />
          </li>
          <li>
            <div className='separator'></div>
            <DateTimePicker label={t('desiredDateTime')} />
          </li>
          <li>
            <div className='separator'></div>
            <Submit />
          </li>
        </ol>
      </div>
    </>
  );
};

export default RegistrationBody;
