import React, { useState, useEffect } from 'react';
import LanguageSelector from '../languageSelector/languageSelector';
import { useTranslation } from '../../utils/translate';
import { ReactComponent as MapIcon } from '../../assets/svg/mapIcon.svg';
import { Button } from '../common/forms';

interface Props {
  onToggleMobileServices: (onOff: boolean) => void;
}

const MobileServices = (props: Props) => {
  const { t } = useTranslation();
  const [isContentVisible, setContentVisible] = useState(false);
  const [isMobileView, setMobileView] = useState(window.innerWidth < 768);

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
    props.onToggleMobileServices(!isContentVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isMobileView) return null; // Don't render anything if not in mobile view

  return (
    <>
      <div className='mobileServices'>
        <div className='ribbon'>
          <LanguageSelector location='inForm' />
          <Button
            customClass='servicesButton'
            type='info'
            label={isContentVisible ? t('booking') : t('information')}
            onClick={toggleContent}
          />
        </div>
        <div
          className={`servicesContent ${isContentVisible ? 'active show' : ''}`}
        >
          <div className='upper'>
            <a
              href='https://helenspetcare.eu'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='' src={'/img/logo.png'} alt='Helenspetcare.eu' title='Helenspetcare.eu' />
            </a>
          </div>

          <a
            className='address'
            target='_blank'
            href="https://www.google.com/maps/place/Helen's+Petcare/@59.4250106,24.7015395,17z/data=!3m1!4b1!4m6!3m5!1s0x46929313d4ae83c5:0x4c86bf8d8d60b0a1!8m2!3d59.4250106!4d24.7015395!16s%2Fg%2F11gmxlwvyq"
          >
            <div className='title'>
              {t('address')} <MapIcon className='mapIcon' />
            </div>
            <div className='value'>Mustamäe tee 29, Tallinn</div>
          </a>
          <div className='services'>
            <div className='header'>{t('sidebarTitle1')}</div>
            <div className='content'>{t('sidebarText1')}</div>
            <div className='header'>{t('sidebarTitle2')}</div>
            <div className='content'>{t('sidebarText2')}</div>
            <div className='header'>{t('sidebarTitle3')}</div>
            <div className='content'>{t('sidebarText3')}</div>
            <div className='header'>{t('sidebarTitle4')}</div>
            <div className='content'>{t('sidebarText4')}</div>
            <div className='header'>{t('sidebarTitle5')}</div>
            <div className='content'>{t('sidebarText5')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileServices;
