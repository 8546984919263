import { useTranslation } from '../../utils/translate';
import RootState from '../../interfaces/root.interface';
import { useSelector } from 'react-redux';

const SubmitOverlay = () => {
  const { t } = useTranslation();
  const currentFormValues = useSelector((state: RootState) => state.form);

  const isSubmitOverlayActive = useSelector(
    (state: RootState) => state.overlay.onOff
  );

  return (
    <>
      {isSubmitOverlayActive && (
        <div className='submitOverlay'>
          <div className='inner'>
            <span>
              {currentFormValues.customerSurname &&
                `${t('afterSubmitPrefix')} ${
                  currentFormValues.customerSurname + ','
                } ${t('willContactYou')} `}
              {!currentFormValues.customerSurname && t('afterSubmit')}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default SubmitOverlay;
