import { createSlice } from '@reduxjs/toolkit';
import { Notification } from '../../interfaces/notifications.interface';

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState: {
    notification: {} as Notification
  },
  reducers: {
    setNotification(state, action) {
      state.notification = action.payload;
    }
  }
});

export const { setNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
