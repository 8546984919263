import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setCustomerPhoneNumber } from '../../store/slices/formSlice';
import { useTranslation } from '../../utils/translate';
import { TextInput } from '../common/forms';

const CustomerPhoneNumber = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    useSelector((state: RootState) => state.form.phoneNumber || null)
  );

  useEffect(() => {
    dispatch(setCustomerPhoneNumber(phoneNumber));
  }, [phoneNumber]);

  return (
    <div className='customerPhoneNumber formItem'>
      <div className='body'>
        <TextInput
          name={'customerPhoneNumber'}
          placeholder={t('phoneNumber')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPhoneNumber(e.target.value)
          }
          value={phoneNumber}
          label={t('phoneNumber')}
        />
      </div>
    </div>
  );
};

export default CustomerPhoneNumber;
