import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setChosenAnimal } from '../../store/slices/formSlice';
import { useTranslation } from '../../utils/translate';

const AnimalType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [animal, setAnimal] = useState<string | null>(
    useSelector((state: RootState) => state.form.animalType || null)
  );

  useEffect(() => {
    dispatch(setChosenAnimal(animal));
  }, [animal]);

  return (
    <div className='animalType'>
      <div className='header'>{t('forWhom')}</div>
      <div className='body'>
        <div
          className={'cat ' + (animal === 'cat' ? ' chosen' : '')}
          onClick={() => setAnimal('cat')}
        >
          <div className='overlay'></div>
          <div className='title'>{t('cat')}</div>
          <object data='./img/cat.svg' type='image/svg+xml'>
            <img src='./img/cat.svg' />
          </object>
        </div>
        <div
          className={'dog ' + (animal === 'dog' ? ' chosen' : '')}
          onClick={() => setAnimal('dog')}
        >
          <div className='overlay'></div>
          <div className='title'>{t('dog')}</div>
          <object data='./img/dog.svg' type='image/svg+xml'>
            <img src='./img/dog.svg' />
          </object>
        </div>
      </div>
    </div>
  );
};

export default AnimalType;
