import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setAnimalBreed, setAnimalIsMixed } from '../../store/slices/formSlice';
import { useTranslation } from '../../utils/translate';
import { CheckBox, TextInput } from '../common/forms';

const Breed = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [breed, setBreed] = useState<string | null>(
    useSelector((state: RootState) => state.form.breed || null)
  );

  const [isMixed, setIsMixed] = useState<boolean>(
    useSelector((state: RootState) => state.form.isMixed || false)
  );

  useEffect(() => {
    dispatch(setAnimalBreed(breed));
  }, [breed]);

  useEffect(() => {
    dispatch(setAnimalIsMixed(isMixed));
  }, [isMixed]);

  return (
    <div className='breed formItem'>
      <div className='header'>{t('breed')}</div>
      <div className='body'>
        <div className='isMixed'>
          <span>{t('isMixed')}</span>
          <CheckBox
            name={'model'}
            checked={isMixed}
            onChange={() => {
              setIsMixed(!isMixed);
            }}
          />
        </div>
        <TextInput
          name={'breed'}
          placeholder={t('breed')}
          disabled={isMixed}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBreed(e.target.value)
          }
          value={breed}
        />
      </div>
    </div>
  );
};

export default Breed;
