import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Estonian from 'flatpickr/dist/l10n/et.js';
import Finnish from 'flatpickr/dist/l10n/fi.js';
import Russian from 'flatpickr/dist/l10n/ru.js';
import English from 'flatpickr/dist/l10n/default.js';

interface LanguageState {
  language: string;
  flatpickrLanguage: any; // Adjust type as needed
}

const initialState: LanguageState = {
  language: 'ee', // Default language
  flatpickrLanguage: Estonian // Default Flatpickr language
};

const LanguageSlice = createSlice({
  name: 'LanguageSlice',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;

      // Set Flatpickr language based on selected language
      switch (action.payload) {
        case 'ee':
          state.flatpickrLanguage = Estonian.et;
          break;
        case 'fi':
          state.flatpickrLanguage = Finnish.fi;
          break;
        case 'ru':
          state.flatpickrLanguage = Russian.ru;
          break;
        case 'en':
          state.flatpickrLanguage = English.en; // English
          break;
        default:
          state.flatpickrLanguage = English.en; // Fallback
      }
    }
  }
});

export const { setLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
