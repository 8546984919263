import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setNotification } from '../../store/slices/notificationsSlice';

// TODO for far future: Multiple notifications at the same time
// by turning the specific redux state {} into an [] of notifications

export const Notifications = () => {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [progBarWidth, setProgBarWidth] = useState<number>(100);
  const notification = useSelector(
    (state: RootState) => state.notification.notification
  );

  useEffect(() => {
    if (notification && notification?.string) {
      setShowNotification(true);
      const refreshIntervalId = setInterval(function () {
        if (progBarWidth == 0) {
          setProgBarWidth(100);
        } else {
          setProgBarWidth((progBarWidth) => progBarWidth - 1);
        }
      }, 50);

      setTimeout(() => {
        setShowNotification(false);
        dispatch(setNotification(null));
        clearInterval(refreshIntervalId);
        setProgBarWidth(100);
      }, 6500);
    }
  }, [notification]);

  return (
    <div
      className={
        'notification ' +
        (showNotification ? 'show' : 'hide') +
        (notification?.type ? ' ' + notification.type.toString() : '')
      }
    >
      <div className='inner'>
        <div className='left'>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <div className='center'>
          <div className='title'>
            <div>{notification?.string}</div>
            <div className='progressBar'>
              <div className='bar' style={{ width: progBarWidth + '%' }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className='right hover' onClick={() => setShowNotification(false)}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default Notifications;
