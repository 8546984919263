import { ChangeEvent } from 'react';

interface props {
  label?: string | boolean;
  name: string;
  value?: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const CheckBox = (props: props) => {
  return (
    <div className='d-flex'>
      <input
        className='form-check-input'
        type='checkbox'
        value={props.value}
        name={props.name}
        onChange={(e) => props.onChange(e)}
        checked={props.checked}
      />
      {props.label && <span>{props.label}</span>}
    </div>
  );
};

export default CheckBox;
