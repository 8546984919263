import { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../interfaces/root.interface';
import { setDateTime } from '../../../store/slices/formSlice';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate'; // Make sure to import the plugin
import { useTranslation } from '../../../utils/translate';

interface Props {
  label?: string | boolean;
  value?: string;
  customClass?: string;
  required?: boolean;
  icon?: string;
  noDirectInput?: boolean;
}

const DateTimePicker = (props: Props) => {
  const { t } = useTranslation();
  const confirmConfig = {
    confirmText: t('confirm'), // Optional: Custom button text
    showAlways: true, // Optional: Show the button always
    theme: 'light' // Optional: Theme for the button
  };

  const flatpickrLanguage = useSelector(
    (state: RootState) => state.language.flatpickrLanguage
  );

  const dispatch = useDispatch();
  const [dateTime, _setDateTime] = useState<string | null>(
    useSelector((state: RootState) => state.form.datetime || null)
  );

  useEffect(() => {
    dispatch(setDateTime(dateTime));
  }, [dateTime]);

  const [date, setDate] = useState<Date>(() => {
    if (props.value) {
      return new Date(props.value);
    } else {
      return new Date(moment(new Date()).format('YYYY-MM-DD kk:mm:ss'));
    }
  });

  // without useEffect the date value will not update when we pass new props
  useEffect(() => {
    if (props.value) {
      setDate(new Date(props.value));
    }
  }, [props.value]);

  const handleClose = (selectedDates: Date[]) => {
    if (selectedDates.length > 0) {
      _setDateTime(moment(selectedDates[0]).format('YYYY-MM-DD kk:mm:ss'));
    }
  };

  return (
    <>
      <div className='customerSurname formItem'>
        <div className='body'>
          {props.label && (
            <label className={'label' + (props.required ? ' required' : '')}>
              {props.label}
            </label>
          )}
          <div className='datePicker position-relative'>
            <Flatpickr
              className={'form-control ' + (props.customClass || '')}
              data-enable-time
              readOnly={true}
              value={date}
              options={{
                allowInput: true,
                dateFormat: 'd/m/Y H:i',
                time_24hr: true,
                locale: flatpickrLanguage,
                plugins: [confirmDatePlugin(confirmConfig)]
              }}
              required
              onClose={handleClose} // Save values on close
              onChange={(e) => {
                _setDateTime(moment(e[0]).format('YYYY-MM-DD kk:mm:ss'));
              }}
            />
            <div className='calendarIcon'>
              <FontAwesomeIcon icon={faCalendarCheck} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateTimePicker;
