import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setCustomerSurname } from '../../store/slices/formSlice';
import { useTranslation } from '../../utils/translate';
import { TextInput } from '../common/forms';

const CustomerSurname = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [surname, setSurname] = useState<string | null>(
    useSelector((state: RootState) => state.form.customerSurname || null)
  );

  useEffect(() => {
    dispatch(setCustomerSurname(surname));
  }, [surname]);

  return (
    <div className='customerSurname formItem'>
      <div className='body'>
        <TextInput
          name={'customerSurname'}
          placeholder={t('customerSurname')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSurname(e.target.value)
          }
          value={surname}
          label={t('customerSurname')}
        />
      </div>
    </div>
  );
};

export default CustomerSurname;
