import { createSlice } from '@reduxjs/toolkit';

const overlaySlice = createSlice({
  name: 'notificationsSlice',
  initialState: {
    onOff: false
  },
  reducers: {
    setOverlay(state, action) {
      state.onOff = action.payload;
    }
  }
});

export const { setOverlay } = overlaySlice.actions;

export default overlaySlice.reducer;
