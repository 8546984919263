import RegistrationBody from './registrationBody';

const RegistrationForm = () => {
  return (
    <>
      <RegistrationBody />
    </>
  );
};

export default RegistrationForm;
