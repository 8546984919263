import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../interfaces/root.interface';
import { setAnimalAge } from '../../store/slices/formSlice';
import { useTranslation } from '../../utils/translate';
import { TextInput } from '../common/forms';

const AnimalAge = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [age, setAge] = useState<string | null>(
    useSelector((state: RootState) => state.form.animalAge || null)
  );

  useEffect(() => {
    if (age) {
      dispatch(setAnimalAge(age));
    }
  }, [age]);

  return (
    <div className='age formItem'>
      <div className='body'>
        <TextInput
          name={'animalAge'}
          placeholder={t('age')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAge(e.target.value)
          }
          label={t('animalAge')}
          value={age}
        />
      </div>
    </div>
  );
};

export default AnimalAge;
