import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import availableLanguages from '../../config/languages';
import RootState from '../../interfaces/root.interface';
import { setLanguage } from '../../store/slices/languageSlice';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrowDown.svg';

interface Props {
  location: string;
}

const LanguageSelector = (props: Props) => {
  const dispatch = useDispatch();
  const [showLanguageSelector, setShowLanguageSelector] =
    useState<boolean>(false);

  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  const iso3 = availableLanguages.find(
    (language) => language.iso2 == currentLanguage
  );

  const setChosenLang = (input: string) => {
    dispatch(setLanguage(input));
  };

  return (
    <div
      className={'LanguageSelector ' + props.location}
      onMouseOver={() => setShowLanguageSelector(true)}
      onMouseOut={() => setShowLanguageSelector(false)}
      onClick={() => setShowLanguageSelector(!showLanguageSelector)}
    >
      <div className='langItem'>
        <img src={'./img/flags/' + currentLanguage + '.png'} alt='' />
        <span className='langIso3'>{iso3?.iso3}</span>

        <ArrowDown className='arrowDown' />
      </div>
      {showLanguageSelector && (
        <div className='dropdown'>
          <div className='inner'>
            <div className='arrow'></div>
            {availableLanguages.map((language, index) => {
              if (currentLanguage !== language.iso2) {
                return (
                  <div
                    key={index}
                    className='langItem'
                    onClick={() => setChosenLang(language.iso2)}
                  >
                    <img src={'./img/flags/' + language.iso2 + '.png'} alt='' />
                    <span className='langIso3'>{language.iso3}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default LanguageSelector;
