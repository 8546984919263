import { combineReducers } from 'redux';
import languageSlice from './slices/languageSlice';
import loadingSlice from './slices/loadingSlice';
import notificationsSlice from './slices/notificationsSlice';
import formSlice from './slices/formSlice';
import overlaySlice from './slices/overlaySlice';

export const rootReducer = combineReducers({
  notification: notificationsSlice,
  loading: loadingSlice,
  form: formSlice,
  language: languageSlice,
  overlay: overlaySlice
});
